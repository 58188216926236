// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

import { StaticAuthProvider, RefreshingAuthProvider } from '@twurple/auth';
import { ChatClient } from '@twurple/chat';
import { ApiClient } from '@twurple/api';
import { PubSubClient, PubSubSubscriptionMessage, PubSubRedemptionMessage, PubSubBitsMessage } from '@twurple/pubsub';
import { Configuration, OpenAIApi } from "openai";

// Hookup Yarn Packages
global.$ = require('jquery')
global.bootstrap = require('bootstrap')
global.moment = require('moment')
global.StaticAuthProvider = StaticAuthProvider
global.RefreshingAuthProvider = RefreshingAuthProvider
global.ChatClient = ChatClient
global.ApiClient = ApiClient
global.PubSubClient = PubSubClient
global.PubSubSubscriptionMessage = PubSubSubscriptionMessage
global.PubSubRedemptionMessage = PubSubRedemptionMessage
global.PubSubBitsMessage = PubSubBitsMessage

global.OpenAIApi = OpenAIApi
global.OpenAIConf = Configuration
global.p5 = require('p5')
global.p5.sound = require('p5/lib/addons/p5.sound');
global.anime = require('animejs/lib/anime.js')

import "@fortawesome/fontawesome-free/js/all"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
